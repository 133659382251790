
export default {
  name: 'ProjectSectionSelector',
  props: {
    section: {
      required: true,
      type: String,
      default: ''
    },
    variant: {
      required: true,
      type: String,
      default: ''
    },
    multiProject: {
      required: false,
      type: Boolean,
      default: false
    },
    data: {
      required: true,
      type: [Object, Array],
      default: function () {
        return {}
      }
    },
    extra: {
      required: false,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    enabled: {
      required: false,
      type: Boolean,
      default: false
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  computed: {
    activeDataSet() {
      if (this.multiProject) {
        return this.data[this.$store.state.project.activeProject]
      }
      return this.data
    },
    isEmbedPath() {
      return this.$route.path.includes('/embed');
    }
  }
}
