
export default {
  name: 'SectionGalleryV1',
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  computed: {
    content() {
      return this.data
    },
    images() {
      return this.data.images
    },
    videos() {
      return this.data.videos
    },
    brochure() {
      return this.data.brochure
    }
  }
}
