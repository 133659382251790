
import SectionSustainabilityV3 from '../../sections/general/sustainability/SectionSustainabilityV3'
import SectionSustainabilityV2 from '../../sections/general/sustainability/SectionSustainabilityV2'
import SectionSustainabilityV1 from '../../sections/general/sustainability/SectionSustainabilityV1'

export default {
  name: 'SectionSustainability',
  components: {},
  props: {
    variant: {
      required: true,
      type: String,
      default: ''
    },
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      componentVariant: null
    }
  },
  watch: {
    variant: function (newVariant, oldVariant) {
      this.handleVariant(newVariant)
    }
  },
  created() {
    this.handleVariant(this.variant)
  },
  methods: {
    handleVariant(variant) {
      switch (variant) {
        case 'v3':
          this.componentVariant = SectionSustainabilityV3
          break
        case 'v2':
          this.componentVariant = SectionSustainabilityV2
          break
        default:
          this.componentVariant = SectionSustainabilityV1
          break
      }
    }
  }
}
