
export default {
  name: 'SectionSelector',
  props: {
    section: {
      required: true,
      type: String,
      default: ''
    },
    data: {
      required: true,
      type: [Object, Array],
      default: function () {
        return {}
      }
    },
    extra: {
      required: false,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    enabled: {
      required: false,
      type: Boolean,
      default: false
    },
    hash: {
      required: true,
      type: String,
      default: ''
    },
    title: {
      required: false,
      type: String,
      default: ''
    },
    subtitle: {
      required: false,
      type: String,
      default: ''
    }
  }
}
